/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Navigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { BackIcon, PTTransparentIcon, SpinnerIcon } from '../../assets/icons';
import PTButton from '../../components/Button/Button';
import Footer from '../../components/Footer';
import ObDecorations from '../../components/ObDecorations';
import { get2FAState, send2FASMSOTP, verify2FACode } from '../../services/auth';
import clsxm from '../../lib/clsxm';

const helperText = {
  authApp: () => <>Please enter the code generated by your authenticator app</>,
  backupCode: () => <>Please enter one of your unused backup codes</>,
  sms: (phone) => (
    <>
      Please enter the code sent to your number ending with{' '}
      <b className="font-inter-semibold">****{phone?.substring(phone?.length - 4)}</b>
    </>
  ),
};

const TwoFactorAuth = () => {
  const location = useLocation();
  const jwtToken = location?.state?.token || '';
  const [page, setPage] = useState(0);
  const [verificationMethod, setVerificationMethod] = useState('authApp');
  const [twoFAState, setTwoFAState] = useState('');
  const [sendingSMS, setSendingSMS] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const reCaptchaRef = useRef();

  const fetch2FASMSPhone = async () => {
    try {
      setLoading(true);
      const res = await get2FAState(jwtToken);
      if (res) {
        setTwoFAState(res.data);
        setVerificationMethod(res.data?.totp?.enabled ? 'authApp' : 'sms');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch2FASMSPhone();
  }, []);

  const handleTwoFactorAuth = async (values, formActions) => {
    let code;
    if (verificationMethod === 'backupCode') {
      code = values.backupCode || '';
    } else {
      code = Object.values({
        digit0: values.digit0 || '',
        digit1: values.digit1 || '',
        digit2: values.digit2 || '',
        digit3: values.digit3 || '',
        digit4: values.digit4 || '',
        digit5: values.digit5 || '',
      }).join('');
    }

    try {
      const response = await verify2FACode({ code, method: verificationMethod }, jwtToken);

      if (response.data.success) {
        if (window?.opener) {
          window.opener.postMessage(response.data.token, process.env.REACT_APP_PLAYGROUNDS_APP_URL);
          window.close();
        }

        Cookies.set('jwtToken', response.data.token, {
          path: '/',
          domain: global.config.ssoAppDomain,
          expires: 1, // 1 day
          secure: true,
        });

        if (!window.localStorage.getItem('playtreksNextStep')?.includes('theplaygrounds.io')) {
          window.location.replace(window.localStorage.getItem('playtreksNextStep'));
        }
      }
    } catch (error) {
      if (error.response.status === 429) {
        toast.error('Too many requests. Please try again later.');
      } else if (error.response?.data?.message || error.response?.data?.msg) {
        formActions.setStatus({
          genericError: error.response.data.message || error.response.data.msg,
        });
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleContinue = async () => {
    if (verificationMethod === 'sms') {
      setSendingSMS(true);
      try {
        const token = await reCaptchaRef.current.executeAsync();
        reCaptchaRef.current.reset();
        const res = await send2FASMSOTP(jwtToken, token);

        if (!res.data.success) {
          toast.error('Unable to send OTP. Please try again later.');
        }

        if (res.data.success) {
          toast.success(res.data.msg);
        }
        setSendingSMS(false);
      } catch (error) {
        reCaptchaRef.current.reset();

        toast.error(
          error?.response?.data?.msg || 'An unexpected error occurred. Please try again.'
        );
        setSendingSMS(false);
      }
    }
    setPage((currPage) => currPage + 1);
  };

  // const verifyCaptchaCallback = (response) {}

  const handleOnCaptchaError = () => {
    reCaptchaRef.current.reset();
  };

  const handleOnCaptchaExpiry = () => {
    reCaptchaRef.current.reset();
  };

  if (!jwtToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div>
      {/* Logo */}
      <a href="https://playtreks.com">
        <img alt="playtreks" src={PTTransparentIcon} className="loginLogo" />
      </a>

      <ObDecorations />

      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_INVISIBLE_CAPTCHA_SITE_KEY}
        size="invisible"
        theme="dark"
        // onChange={verifyCaptchaCallback}
        onExpired={handleOnCaptchaExpiry}
        onErrored={handleOnCaptchaError}
      />

      <div
        className="absolute rounded-xl bg-gray-900 mt-32 top-0 
      left-1/2 -translate-x-1/2  w-11/12 sm:w-[479px] sm:h-fit-content"
      >
        <div className="flex px-3 sm:px-10 pt-8 justify-center items-center mb-8">
          {page === 1 && (
            <div className="hover:text-primary cursor-pointer" onClick={() => setPage(0)}>
              <BackIcon />
            </div>
          )}
          <div className="text-xl ml-5 md:text-4xl font-inter-semibold">Verify your identity</div>
        </div>

        <div className="px-3 sm:px-12 h-full">
          {page === 0 &&
            (loading ? (
              <div className="flex justify-center items-center h-[150px]">
                <SpinnerIcon />
              </div>
            ) : (
              <div className="mb-5">
                <p className="flex-none w-full text-white mb-4 text-lg">
                  Please choose your verification method and click continue.
                </p>
                <div className="text-center">
                  <p className="mb-2 text-gray-300 text-sm">Choose your method</p>
                  <select
                    className="bg-primary font-inter-semibold 
                            text-sm text-gray-900 p-4 md:py-3 cursor-pointer rounded"
                    value={verificationMethod}
                    onChange={(e) => setVerificationMethod(e.target.value)}
                    name="verificationMethod"
                  >
                    {twoFAState?.totp?.enabled && <option value="authApp">Authenticator</option>}
                    {twoFAState?.sms?.enabled && <option value="sms">SMS</option>}
                    {(twoFAState?.sms?.enabled || twoFAState?.totp?.enabled) && (
                      <option value="backupCode">Backup code</option>
                    )}
                  </select>
                </div>
                <div className="flex justify-end mt-12">
                  <PTButton
                    label={sendingSMS ? 'Sending OTP' : 'Continue'}
                    disabled={sendingSMS}
                    onClick={handleContinue}
                    endIcon={sendingSMS ? <SpinnerIcon height={26} /> : null}
                  />
                </div>
              </div>
            ))}

          {page === 1 && (
            <Formik
              enableReinitialize
              initialValues={{
                digit0: '',
                digit1: '',
                digit2: '',
                digit3: '',
                digit4: '',
                digit5: '',
                backupCode: '',
              }}
              initialStatus={{ genericError: '' }}
              onSubmit={handleTwoFactorAuth}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  status: { genericError },
                  handleSubmit,
                  isSubmitting,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                } = props;

                const handleCodeChange = (index, value, formikProps) => {
                  const nextIndex = index + 1;
                  const prevIndex = index;

                  if (value !== '') {
                    if (nextIndex <= 5) {
                      const nextField = document.getElementsByName(`digit${nextIndex}`)[0];
                      nextField.focus();
                    }
                  } else if (prevIndex >= 0) {
                    const prevField = document.getElementsByName(`digit${prevIndex}`)[0];
                    prevField.focus();
                  }
                  formikProps.setFieldValue(`digit${index}`, value);

                  formikProps.setStatus((status) => ({
                    ...status,
                    genericError: '',
                  }));
                };

                const handlePaste = (event) => {
                  const pastedValue = event.clipboardData.getData('text/plain');
                  const digits = pastedValue.split('');
                  const maxDigits = 6;
                  const numDigits = Math.min(digits.length, maxDigits);

                  for (let i = 0; i < numDigits; i += 1) {
                    setFieldValue(`digit${i}`, digits[i]);
                  }
                  if (numDigits < maxDigits) {
                    const nextField = document.getElementsByName(`digit${numDigits}`)[0];
                    nextField.focus();
                  } else {
                    const nextField = document.getElementsByName(`digit${numDigits - 1}`)[0];
                    nextField.blur();
                  }

                  event.preventDefault();
                };

                const handleKeyDown = (event) => {
                  if (event.key === 'Backspace' && event.target.value === '') {
                    const prevIndex = event.target.name.replace('digit', '') - 1;
                    if (prevIndex >= 0) {
                      const prevField = document.getElementsByName(`digit${prevIndex}`)[0];
                      prevField.focus();
                    }
                  }
                };

                return (
                  <Form>
                    <div className="mb-4">
                      <div className="text-gray-300 text-sm mb-1 mt-3">
                        {helperText[verificationMethod](twoFAState?.sms?.phone)}
                      </div>

                      {verificationMethod === 'backupCode' ? (
                        <div className="mt-5">
                          <Field
                            name="backupCode"
                            type="text"
                            placeholder="Enter backup code (e.g. 0c8fcb48)"
                            className={clsxm(
                              'w-full h-16 px-4 rounded-xl border text-xl font-inter-semibold',
                              'bg-gray-700 bg-opacity-10',
                              'border-primary text-primary',
                              {
                                'border-error border-2 text-error animate-error': genericError,
                              }
                            )}
                            autoComplete="off"
                          />
                        </div>
                      ) : (
                        <div className="flex items-center mt-5 gap-3">
                          {[0, 1, 2, 3, 4, 5].map((index) => (
                            <div key={index} className="w-16 h-16">
                              <Field
                                value={values[`digit${index}`]}
                                name={`digit${index}`}
                                pattern="[0-9]*"
                                type="number"
                                error={touched[`digit${index}`] && !values[`digit${index}`]}
                                onBlur={handleBlur}
                                onChange={(e) => handleCodeChange(index, e?.target?.value, props)}
                                onPaste={handlePaste}
                                autoFocus={index === 0}
                                onFocus={() => setFocusedInput(index)}
                                onKeyDown={(e) => handleKeyDown(e)}
                                autoComplete="off"
                                maxLength={1}
                                className={clsxm(
                                  'w-full h-full flex  flex-col items-center justify-center text-center outline-none',
                                  'px-3 rounded-xl border text-xl font-inter-semibold',
                                  {
                                    'bg-gray-700 bg-opacity-10':
                                      focusedInput === index || values[`digit${index}`] !== '',
                                    'bg-gray-700 bg-opacity-25':
                                      focusedInput !== index && values[`digit${index}`] === '',
                                  },
                                  {
                                    'border-primary text-primary':
                                      focusedInput === index || values[`digit${index}`] !== '',
                                    'border-gray-300 text-gray-500':
                                      focusedInput !== index && values[`digit${index}`] === '',
                                    'border-error border-2 text-error animate-error':
                                      genericError && values[`digit${index}`] !== '',
                                  }
                                )}
                              />
                            </div>
                          ))}
                        </div>
                      )}

                      {genericError && !genericError.includes('Invalid code. Try again') && (
                        <div className="mt-4 text-red-500 text-sm">{genericError}</div>
                      )}

                      <div className="flex justify-end items-center mt-8">
                        <PTButton
                          label={
                            // eslint-disable-next-line no-nested-ternary
                            isSubmitting
                              ? 'Verifying'
                              : genericError && genericError.includes('Invalid code. Try again')
                              ? 'Wrong code!'
                              : 'Verify'
                          }
                          small
                          primary
                          type="submit"
                          className="font-inter-semibold w-full"
                          onClick={handleSubmit}
                          endIcon={isSubmitting ? <SpinnerIcon width={26} height={26} /> : null}
                          error={genericError && genericError.includes('Invalid code. Try again')}
                          disabled={
                            isSubmitting ||
                            (verificationMethod === 'backupCode'
                              ? !values.backupCode
                              : Object.values({
                                  digit0: values.digit0 || '',
                                  digit1: values.digit1 || '',
                                  digit2: values.digit2 || '',
                                  digit3: values.digit3 || '',
                                  digit4: values.digit4 || '',
                                  digit5: values.digit5 || '',
                                }).some((value) => value === '')) ||
                            genericError
                          }
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TwoFactorAuth;
